@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
*,
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
}

html {
  scroll-behavior: smooth;
}

a,
button {
  transition: background 0.15s ease-in-out, color 0.15s ease-in-out;
}

body h1 {
  font-weight: 600;
  font-size: 96px;
  line-height: 112px;
  letter-spacing: -1.5px;
}

body h2 {
  font-weight: 800;
  font-size: 56px;
  line-height: 67px;
  letter-spacing: -1px;
}

body h3 {
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
}

body h4 {
  font-weight: 600;
  font-size: 34px;
  line-height: 42px;
  letter-spacing: 0.25px;
}

body h5 {
  font-weight: 900;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.5px;
}

body h6 {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.15px;
}

button.swal2-confirm.btn-custom {
  border: none;
  background-color: #282750;
  height: 42px;
  border-radius: 30px;
  outline: none !important;
  box-shadow: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 130px;
  padding-left: 12px;
  padding-right: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  filter: drop-shadow(0px 1px 14px rgba(0, 0, 0, 0.1));
  margin: 0 auto 15px;
}

button.swal2-confirm.btn-custom:hover {
  opacity: 0.8;
}

h2.swal2-title {
  padding-top: 0;
}
div.swal2-html-container {
  margin-top: 0;
  margin-bottom: 15px;
  white-space: break-spaces;
  font-size: 17px;
  line-height: 25px;
  color: rgba(97, 97, 97, 0.9);
}

input:disabled,
textarea:disabled {
  opacity: 0.86;
  cursor: progress;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: #01579b;
  box-shadow: 0 0 0px 1000px transparent inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

